<template>
	<div class="container">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div class="content">
					<el-form-item label="广告名称：" :inline-message="true" prop="bannerName">
						<el-input
							v-model="ruleForm.bannerName"
							style="width: 300px;"
							placeholder="最多输入20个字"
							maxlength="20"
						></el-input>
					</el-form-item>
					<el-form-item label="广告位置：" :inline-message="true" prop="adPosition">
						<el-select v-model="ruleForm.bannerType" placeholder="请选择广告位置">
							<el-option
								v-for="item in adPosition"
								:key="item.value"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					
					<el-form-item label="起止时间：" :inline-message="true" prop="selectTime">
						<el-date-picker
							v-model="ruleForm.selectTime"
							type="datetimerange"
							range-separator="～"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							align="right"
							value-format="yyyy-MM-dd HH:mm:ss"
						></el-date-picker>
					</el-form-item>
					
                    <el-form-item
						label="选择链接:"
						prop="linkUrl"
					>
						<div class="link-modal-info" @click="showModal()">
                            <div class="link-info" v-if="!ruleForm.linkUrl.data">
                                <div class="cont" style="background: transparent;color: #409eff;">请选择页面</div>
                                <div class="icon" style="background: transparent;">
                                    <i class="el-icon-arrow-down" style="color: #409eff;font-size: 16px;background: transparent;"></i>
                                </div>
                            </div>
                            <div class="link-info" v-else>
                                <div class="cont">{{ruleForm.linkUrl.text}}</div>
                                <div class="icon">
                                    <i class="el-icon-arrow-down" style="color: #fff;font-size: 16px;"></i>
                                </div>
                            </div>
                        </div>
					</el-form-item>
					<el-form-item label="广告图片：" :inline-message="true" prop="bannerImgUrl">
						<el-upload
							class="avatar-uploader"
							:action="imgApi"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
						>
							<img
								v-if="ruleForm.bannerImgUrl"
								:src="ruleForm.bannerImgUrl"
								:style="ruleForm.bannerType == 3 ?'width:146px;height:146px;':'width:351px;height:140px;'"
								class="avatar"
							/>
							<i
								v-else
								class="el-icon-plus"
								style="font-size: 24px;color:#999999;margin-top:60px;"
								:style="ruleForm.bannerType == 3 ?'width:144px;height:84px;':'width:349px;height:84px;'"
							></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="广告图片主色：" :inline-message="true">
						<!-- <el-color-picker v-model="ruleForm.color"></el-color-picker> -->
						<el-input v-model="ruleForm.color" style="width: 300px;margin-right:10px;"></el-input>
						<el-tooltip
							class="item"
							effect="dark"
							placement="right"
							style="font-size: 20px;color: #e6a23c;"
						>
							<div slot="content">
								普通色值：#A2D0E4
								<br />渐变色值：#A2D0E4-#F0FBFF
							</div>
							<i class="el-icon-question"></i>
						</el-tooltip>
					</el-form-item>
                    <el-form-item label="浏览权限：" >
                        <buttonPermissions :datas="'adManageBrowse'">
                            <el-radio v-model="ruleForm.authorType" :label="1">普通用户</el-radio>
                            <el-radio v-model="ruleForm.authorType" :label="2">指定用户</el-radio>
                        </buttonPermissions>
                        <el-checkbox-group v-model="ruleForm.userLevel" v-if="ruleForm.authorType == 1">
                            <el-checkbox :label="0">普通用户</el-checkbox>
                            <el-checkbox :label="level.pusherLevel" v-for="level in LEVELLIST" :key='level.pusherLevel'>{{level.pusherLabel}}</el-checkbox>
                            <el-checkbox :label="999">游客</el-checkbox>
                        </el-checkbox-group>
                        <div v-else>
                            <el-input v-model="ruleForm.userIds" placeholder="请输入用户ID，中间用逗号分割" style="width: 300px;margin-right:10px;" ></el-input>
                        </div>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<div style="height:80px;"></div>
		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler()" :loading="loading">保存</el-button>
		</div>

        <!-- 选择链接弹窗 -->
        <link-modal 
            :showLiveTab="true" 
            :visible="shopVisible" 
            @change="dataChange" 
            @cancel="shopVisible=false">
        </link-modal>
	</div>
</template>

<script>
import { findAdPosition, addAdPosition, updateAdPosition } from "@/api/TurnTomySelf.js";
import adConfig from '@/assets/js/ad-config.js'
import config from '@/config/index'
import linkModal from '@/views/components/linkModal';
export default {
	components: {
        linkModal
	},
	data () {
		return {
            adPosition: adConfig.adPosition,    //广告位置列表
            shopVisible:false,  //链接弹窗开关
			ruleForm: {
				bannerImgUrl: "",
				bannerName: "",
				bannerStatus: 0,
				bannerType: 1,
				begTime: "",
				endTime: "",
				id: 0,
				linkUrl: {},
				color: "",
                selectTime: [],
                authorType:1,   //浏览权限类型
                userLevel:[0,1,2,3,4,999],   //浏览权限
                userIds:'',  //指定人群
			},
			rules: {
				bannerName: [{
					required: true,
					message: "请输入广告名称",
					trigger: "blur",
				}],
				selectTime: [{
					required: true,
					message: "请选择起止时间",
					trigger: "blur",
				}],
			},
			imgApi: config.UPLOAD_IMG,
			loading: false,  //保存接口防止多次点击
		};
	},
	computed: {

	},
	created () {
		if (this.$route.query.id) {
			this.getAdDetial();
		}
	},

	methods: {
		
		//获取详细数据
		async getAdDetial () {
			let res = await findAdPosition({ id: this.$route.query.id });
			this.ruleForm = {
				id: this.$route.query.id,
				bannerImgUrl: res.data.bannerImgUrl,
				bannerName: res.data.bannerName,
				bannerStatus: res.data.bannerStatus,
				bannerType: res.data.bannerType,
				begTime: res.data.begTime,
				endTime: res.data.endTime,
				linkUrl: {},
				color: res.data.color,
				selectTime: [],
                authorType:res.data.authorType ? res.data.authorType : 1,
                userLevel:res.data.userLevel ? res.data.userLevel : [0,1,2,3,4,999],
                userIds:res.data.userIds ? res.data.userIds.join(',') : [],
            }

            try {
                this.ruleForm.linkUrl = JSON.parse(res.data.linkUrl);
            } catch (error) {
                this.ruleForm.linkUrl = res.data.linkUrl;
            }
			if (res.data.begTime) {
				this.ruleForm.selectTime = [res.data.begTime, res.data.endTime];
			}
		},
		// 上传规格图成功钩子
		handleAvatarSuccess (res) {
			this.ruleForm.bannerImgUrl = res.data[0];
			return true;
		},
		// 上传规格图前钩子
		beforeAvatarUpload (file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
			const isLt3M = file.size / 1024 / 1024 < 3;
			if (!isJPG) {
				this.$message.error('图片必须是gif、 JPG或者PNG格式!');
			}
			if (!isLt3M) {
				this.$message.error('图片大小请控制在3M以内!');
			}
			return isJPG && isLt3M;
		},
		// 保存
		saveSupiler () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//保存
		async sureSave () {
			this.loading = true
			let params = JSON.parse(JSON.stringify(this.ruleForm));
            
            
			params.begTime = this.ruleForm.selectTime[0];
			params.endTime = this.ruleForm.selectTime[1];
            //逗号分隔
            if (this.ruleForm.authorType == 1) {
                if (!this.ruleForm.userLevel.length) {
                    this.$message({
                        showClose: true,
                        type: 'error',
                        message: '至少选中一个用户等级'
                    });
                    this.loading = false;
                    return;
                }
                params.userIds = [];
            }else{
                if (!this.ruleForm.userIds) {
                    this.$message({
                        showClose: true,
                        type: 'error',
                        message: '指定人群id不能为空'
                    });
                    this.loading = false;
                    return;
                }
                params.userLevel = [];
                let str = params.userIds;
                str = str.replace('，', ",");
                str = str.split(',');
                params.userIds = str;
                console.log(params.userIds);
            }
            params.linkUrl = JSON.stringify(params.linkUrl);
			let res;
			if (this.$route.query.id != undefined && this.$route.query.id != 0) {
				res = await updateAdPosition(params);
			} else {
				res = await addAdPosition(params);
			}

			if (res.success) {
				this.cancelSupiler();
			} else {
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg
				});
			}
			this.loading = false;
		},
		//取消保存
		cancelSupiler () {
			this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
				(item) => {
					return item.path != this.$route.path;
				}
			);
			this.$router.push({
				path: "/setting/adManage/index",
			});
		},
        //链接弹窗
        showModal(){
            this.shopVisible = true;
        },
        //链接变更
        dataChange(record){
            this.ruleForm.linkUrl = record;
        },
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 100%;
	}

	/deep/.el-upload:hover {
		border-color: #409eff;
	}
}

.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
</style>
